<template>
    <BT-Blade-Item
        v-if="currentLocationID != null"
        bladeName="dispatch-hub"
        :canClose="false"
        :canDelete="false"
        :canEdit="false"
        :canRefresh="false"
        :canSave="false"
        :item="{}"
        title="Dispatch"
        :defaultBladeWidth="500">
        <v-expansion-panels v-model="panelV">
            <BT-Blade-Expansion-Items
                addBladeName="dispatch-journey"
                addTrainingBladeName="dispatch-journey-training"
                :canExportCSV="false"
                :headers="[
                    { text: 'Name', value: 'journeyName', title: 1 },
                    { text: 'Departing', value: 'dueStartOn', subtitle: 1, display: true },
                    { text: 'Destinations', value: 'destinationCount', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Destinations: ' },
                    { text: 'Status', value: 'status', display: true }]"
                hideActions
                ignorePermissions
                :itemProperties="['ID','DestinationCount','JourneyName','IsReady','DueStartOn','EndedOn','StartedOn']"
                navigation="journeys"
                :params="{ includeDetails: false, filterBy: 'Not Ended, Ready, StartLocationID', departureLocationID: currentLocationID }"
                title="Journeys">
                <template v-slot:dueStartOn="{ item }">
                    {{ (item.startedOn || item.dueStartOn) | toShortDateAndTime }}
                </template>
                <template v-slot:status="{ item }">
                    {{ item.startedOn == null ? 'Pending' : (item.endedOn != null ? 'Finished' : 'In Progress') }}
                </template>
            </BT-Blade-Expansion-Items>
            <BT-Blade-Expansion-Items
                addBladeName="dispatch-release"
                addTrainingBladeName="dispatch-release-training"
                :canExportCSV="false"
                canSearchLocal
                :headers="[
                    { text: 'Due Date', value: 'dueDepartureOn', textFilter: 'toShortDate', subtitle: 1 },
                    { text: 'Customer', value: 'buyer.companyName', title: 1, prefix: 'Customer: ', searchable: true },
                    { text: 'Courier', value: 'courier', title: 2, prefix: 'Courier: ', display: true },
                    { text: 'Status', value: 'status', subtitle: 2, display: true }]"
                hideActions
                ignorePermissions
                :itemProperties="['ID', 'Buyer', 'Courier', 'IsPickup', 'ReleasedOn','DueDepartureOn']"
                navigation="releases"
                :params="{ includeDetails: false, filterBy: 'Unfulfilled', departureLocationID: currentLocationID }"
                title="Releases"
                useServerPagination>
                <template v-slot:status="{ item }">
                    {{ item.releasedOn != null ? 'Released' : 'Pending' }}
                </template>
                <template v-slot:courier="{ item }">
                    {{ item.isPickup ? '**Pickup' : item.courier.companyName }}
                </template>
            </BT-Blade-Expansion-Items>
            <BT-Blade-Expansion-Items
                addBladeName="driver-hub"
                addTrainingBladeName="driver-training"
                :canExportCSV="false"
                canSearchLocal
                :getSelectQuery="() => { return { mode: 'pickup' } }"
                :headers="[
                    { text: 'Due Date', value: 'dueDepartureOn', textFilter: 'toShortDate', subtitle: 1 },
                    { text: 'Customer', value: 'buyer.companyName', title: 1, prefix: 'Customer: ', searchable: true },
                    { text: 'Status', value: 'status', subtitle: 2, display: true }]"
                hideActions
                ignorePermissions
                :itemProperties="['ID', 'Buyer', 'IsPickup', 'ReleasedOn','DueDepartureOn']"
                navigation="releases"
                :params="{ includeDetails: false, filterBy: 'Unfulfilled,Pickups', departureLocationID: currentLocationID }"
                title="Pickups"
                useServerPagination>
                <template v-slot:status="{ item }">
                    {{ item.releasedOn != null ? 'Released' : 'Pending' }}
                </template>
            </BT-Blade-Expansion-Items>
        </v-expansion-panels>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Dispatches-Blade',
    data: function() {
        return {
            currentLocationID: null,
            panelV: 2,
        }
    },
    async mounted() {
        if (this.currentLocationID == null) {
            this.currentLocationID = this.selectedLocationID();

            if (this.currentLocationID == null) {
                this.currentLocationID = await this.$selectItem({
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    itemValue: 'id',
                    required: true });
            }
        }
    },
}
</script>
