var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentLocationID != null)?_c('BT-Blade-Item',{attrs:{"bladeName":"dispatch-hub","canClose":false,"canDelete":false,"canEdit":false,"canRefresh":false,"canSave":false,"item":{},"title":"Dispatch","defaultBladeWidth":500}},[_c('v-expansion-panels',{model:{value:(_vm.panelV),callback:function ($$v) {_vm.panelV=$$v},expression:"panelV"}},[_c('BT-Blade-Expansion-Items',{attrs:{"addBladeName":"dispatch-journey","addTrainingBladeName":"dispatch-journey-training","canExportCSV":false,"headers":[
                { text: 'Name', value: 'journeyName', title: 1 },
                { text: 'Departing', value: 'dueStartOn', subtitle: 1, display: true },
                { text: 'Destinations', value: 'destinationCount', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Destinations: ' },
                { text: 'Status', value: 'status', display: true }],"hideActions":"","ignorePermissions":"","itemProperties":['ID','DestinationCount','JourneyName','IsReady','DueStartOn','EndedOn','StartedOn'],"navigation":"journeys","params":{ includeDetails: false, filterBy: 'Not Ended, Ready, StartLocationID', departureLocationID: _vm.currentLocationID },"title":"Journeys"},scopedSlots:_vm._u([{key:"dueStartOn",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toShortDateAndTime")((item.startedOn || item.dueStartOn)))+" ")]}},{key:"status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.startedOn == null ? 'Pending' : (item.endedOn != null ? 'Finished' : 'In Progress'))+" ")]}}],null,false,196354595)}),_c('BT-Blade-Expansion-Items',{attrs:{"addBladeName":"dispatch-release","addTrainingBladeName":"dispatch-release-training","canExportCSV":false,"canSearchLocal":"","headers":[
                { text: 'Due Date', value: 'dueDepartureOn', textFilter: 'toShortDate', subtitle: 1 },
                { text: 'Customer', value: 'buyer.companyName', title: 1, prefix: 'Customer: ', searchable: true },
                { text: 'Courier', value: 'courier', title: 2, prefix: 'Courier: ', display: true },
                { text: 'Status', value: 'status', subtitle: 2, display: true }],"hideActions":"","ignorePermissions":"","itemProperties":['ID', 'Buyer', 'Courier', 'IsPickup', 'ReleasedOn','DueDepartureOn'],"navigation":"releases","params":{ includeDetails: false, filterBy: 'Unfulfilled', departureLocationID: _vm.currentLocationID },"title":"Releases","useServerPagination":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.releasedOn != null ? 'Released' : 'Pending')+" ")]}},{key:"courier",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPickup ? '**Pickup' : item.courier.companyName)+" ")]}}],null,false,3794547691)}),_c('BT-Blade-Expansion-Items',{attrs:{"addBladeName":"driver-hub","addTrainingBladeName":"driver-training","canExportCSV":false,"canSearchLocal":"","getSelectQuery":function () { return { mode: 'pickup' } },"headers":[
                { text: 'Due Date', value: 'dueDepartureOn', textFilter: 'toShortDate', subtitle: 1 },
                { text: 'Customer', value: 'buyer.companyName', title: 1, prefix: 'Customer: ', searchable: true },
                { text: 'Status', value: 'status', subtitle: 2, display: true }],"hideActions":"","ignorePermissions":"","itemProperties":['ID', 'Buyer', 'IsPickup', 'ReleasedOn','DueDepartureOn'],"navigation":"releases","params":{ includeDetails: false, filterBy: 'Unfulfilled,Pickups', departureLocationID: _vm.currentLocationID },"title":"Pickups","useServerPagination":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.releasedOn != null ? 'Released' : 'Pending')+" ")]}}],null,false,2309413340)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }